var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-row',{attrs:{"type":"flex","gutter":24}},[_c('a-col',{staticClass:"mb-24",attrs:{"span":24,"md":16}},[_c('a-card',{staticClass:"header-solid h-full",attrs:{"bordered":false,"bodyStyle":{ paddingTop: 0, paddingBottom: '16px' }},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h6',{staticClass:"font-semibold m-0"},[_vm._v("Information de l'agence")])]},proxy:true}])},[_c('div',{staticClass:"text-right",staticStyle:{"margin-bottom":"10px"}},[_c('a-button',{staticStyle:{"margin-left":"10px"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("Retour")])],1),_c('a-row',{attrs:{"gutter":[24, 24]}},[_c('a-col',{attrs:{"span":24}},[_c('a-card',{staticClass:"card-billing-info",attrs:{"bordered":false}},[_c('div',{staticClass:"col-info"},[_c('a-descriptions',{attrs:{"title":'Date de création: ' +
                    new Date(_vm.agence.createdAt).toLocaleString(),"column":2}},[_c('a-descriptions-item',{attrs:{"label":"Nom"}},[_vm._v(" "+_vm._s(_vm.agence.libelle)+" ")]),_c('a-descriptions-item',{attrs:{"label":"Ville"}},[_vm._v(" "+_vm._s(_vm.agence.ville.libelle)+" ")])],1)],1)])],1)],1),_c('a-col',{staticClass:"mb-24",attrs:{"span":24,"md":24}},[_c('a-card',{staticClass:"header-solid h-full",attrs:{"bordered":false,"bodyStyle":{ paddingTop: 0, paddingBottom: '16px' }},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h6',{staticClass:"font-semibold m-0"},[_vm._v("Statistique de l'agence")])]},proxy:true}])},[_c('a-row',{attrs:{"gutter":24}},_vm._l((_vm.stats),function(stat,index){return _c('a-col',{key:index,staticClass:"mb-24",attrs:{"span":12,"lg":12,"xl":12}},[_c('WidgetCounter',{attrs:{"title":stat.title,"value":stat.value,"prefix":stat.prefix,"suffix":stat.suffix,"icon":stat.icon,"status":stat.status}})],1)}),1)],1)],1),_c('a-col',{staticClass:"mb-24",attrs:{"span":24,"md":24}},[_c('a-card',{staticClass:"header-solid h-full",attrs:{"bordered":false,"bodyStyle":{ paddingTop: 0, paddingBottom: '16px' }},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h6',{staticClass:"font-semibold m-0"},[_vm._v("Liste des caissier affectés")])]},proxy:true}])},[_c('a-table',{staticStyle:{"margin-top":"20px"},attrs:{"columns":_vm.columns_caisse,"data-source":_vm.data_caisse,"pagination":true},scopedSlots:_vm._u([{key:"operation",fn:function(text, record){return [_c('router-link',{staticClass:"mx-2",attrs:{"to":{
                    name: 'Caissier_detail',
                    params: { id: record.key },
                  }}},[_c('a-button',{attrs:{"type":"primary","size":"small"}},[_vm._v("Détail")])],1)]}}])})],1)],1),_c('a-col',{staticClass:"mb-24",attrs:{"span":24,"md":24}},[_c('a-card',{staticClass:"header-solid h-full",attrs:{"bordered":false,"bodyStyle":{ paddingTop: 0, paddingBottom: '16px' }},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h6',{staticClass:"font-semibold m-0"},[_vm._v(" Liste des collecteurs affectés ")])]},proxy:true}])},[_c('a-table',{attrs:{"columns":_vm.columns,"data-source":_vm.data},scopedSlots:_vm._u([{key:"operation",fn:function(text, record){return [_c('router-link',{staticClass:"mx-2",attrs:{"to":{
                    name: 'Collecteur_detail',
                    params: { id: record.key },
                  }}},[_c('a-button',{attrs:{"type":"primary","size":"small"}},[_vm._v("Détail")])],1)]}}])})],1)],1)],1)],1),_c('a-col',{staticClass:"mb-24",attrs:{"span":8,"md":8}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }